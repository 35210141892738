@media (min-width: 1400px) {
  .chatboxmain {
    &.chat-active {
      height: calc(100vh - 53vh);
      overflow-y: auto;
      max-height: calc(100vh - 53vh);
    }
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .accountmain {
    .users-list {
      padding: 50px 10px 30px;
    }
  }

  .chatboxmain {
    &.empty-box {
      min-height: calc(100vh - 48vh);
      max-height: calc(100vh - 48vh);
      overflow: hidden;
    }
  }

  ul {
    &.chatboxmain {
      &.chat-active {
        height: calc(100vh - 63vh);
        overflow-y: auto;
        max-height: calc(100vh - 63vh);
      }
    }
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  ul {
    &.chatboxmain {
      &.chat-active {
        height: calc(100vh - 56vh);
        overflow-y: auto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul {
    &.chatboxmain {
      &.chat-active {
        height: calc(100vh - 45vh);
        max-height: calc(100vh - 45vh);
        overflow-y: auto;
      }
    }
  }
  .leftslidetoggle .initials-circle {
    border-radius: 50%;
    font-size: 22px;
    width: 100%;
    height: 80px;
    max-width: 80px;
  }
}

@media (max-width: 1400px) {
  .logout-navbar {
    .navbar-nav {
      a {
        white-space: nowrap;
      }
    }
  }

  .mobile-nav {
    .navbar-nav {
      a {
        white-space: nowrap;
      }
    }
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .blocked_class {
    &::after {
      width: 160px !important;
      height: 135px !important;
    }
  }
  .buffer_day_before {
    &::after {
      width: 120px !important;
      height: 50px !important;
    }
  }
}

@media (max-width: 767px) {
  .itmes-list {
    &.lender-items {
      .card {
        min-height: 200px;
      }
    }
  }

  .delete-head {
    align-items: start !important;
  }

  .fc .fc-scrollgrid {
    table-layout: initial;
    width: 100%;
  }

  .fc .fc-view-harness-active>.fc-view {
    inset: 0px;
    position: absolute;
    overflow-x: scroll;
  }

  .pb132 {
    padding: 0;
  }

  .delete-head a {
    margin-top: 10px;
  }

  .accountmain {
    &.mobile-list {
      .card {
        border-radius: 0px !important;

        .card-img-top {
          border-radius: 0px !important;
        }
      }
    }
  }

  .gmnoprint {
    bottom: inherit !important;
    top: 12% !important;
  }

  .btn {
    padding: 12px 21px !important;
    height: auto !important;
    font-size: var(--font14);
  }

  .location_icon {
    top: 15px;
    bottom: inherit;
  }

  .calender {
    .blocked_class {
      position: relative;

      &::after {
        position: absolute;
        width: 67px !important;
        height: 60px !important;
        left: -11px !important;
        top: 0 !important;
      }
    }
    .buffer_day_before {
      position: relative;

      &::after {
        position: absolute;
        width: 30px !important;
        height: 30px !important;
        left: 10px !important;
        top: 0 !important;
      }
    }

    .fc {
      .fc-daygrid-bg-harness {
        overflow: hidden;
        top: 0;
      }
    }
  }

  .accountmain {
    .chat-view {
      height: 100%;

      ul {
        padding: 10px 30px 20px 0;
      }
    }
  }

  .hero-banner .container {
    width: 100%;
    padding: 0;
  }

  .hd-top-card {
    margin-top: 75px;
  }

  .arrive-details.ps-3 {
    padding-left: 0.5rem !important;
  }

  .mainbox_outer {
    margin-bottom: 81px;
    min-height: 100%;

    &.main_inner {
      min-height: calc(100dvh - 90px);
      padding: 50px 0 0;
      margin-bottom: 20px;
      overflow-y: auto;
    }
  }

  .stars-card .font12 {
    line-height: 24px !important;
  }

  .tab-height {
    margin-bottom: 85px;
  }

  .message-btn {
    height: auto;
  }

  .category-boxes {
    margin-bottom: 0px !important;
  }

  .profilelargeimg {
    &.smallprofile {
      width: 100px;
      height: 100px;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .search-form {
    margin-right: 0;
  }

  .main-slider {
    .hero-banner {
      .swiper-pagination {
        bottom: 12px;
        width: auto !important;
      }

      .swiper-button-next {
        left: 15% !important;
        bottom: 0;
      }

      .swiper-button-prev {
        bottom: 0;
      }
    }
  }

  .itmes-list {
    &.renter-items {
      padding-bottom: 80px;
    }
  }

  .smalltopbar {
    padding: 24px 0 !important;
  }

  .righttoggleicon {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #f0f0f0;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .green-border {
    &.rightsidebar {
      &.rightslidetoggle {
        transition: transform 1s ease-in-out;
        transform: translate(-100%, 0);
        height: calc(100vh - 35vh);
        position: relative;
      }
    }

    &.leftslidetoggle {
      transition: transform 1s ease-in-out;
      transform: translate(-100%, 0);
    }
  }

  .toggledivdetail {
    display: none;
    padding: 0 15px;
    border-bottom: 1px solid #16ff96;
  }

  .h60vh {
    height: 30vh;
  }

  .chatboxmain {
    min-height: 430px;
    max-height: 430px;

    li {
      margin-bottom: 10px;

      img {
        display: block;
      }

      h5 {
        font-size: 16px;
        margin-bottom: 0px !important;

        span {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .inputouter {
    display: flex;
    height: 38px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 0 15px;
    width: 100%;

    .send-msg {
      background: transparent !important;
      border: 0 !important;
      min-height: 100% !important;
      padding: 0 !important;
      border-radius: 0 !important;
    }

    label {
      svg {
        width: 22px;
      }
    }
  }

  .greensmallbtn {
    width: 38px;
    height: 38px;
    background: #16ff96;
    border-radius: 5px;
    min-width: 38px;
  }

  .topbar-chatmain {
    padding: 20px;
    border-bottom: 1px solid #16ff96;
    visibility: visible;
  }

  .accountmain {
    .green-border {
      border-right: 1px solid var(--primary-color);
      width: 100%;
      border: 0;
      min-width: 100%;
    }

    .users-list {
      padding: 10px 0px 10px;

      li {
        padding: 0px;
        margin-bottom: 0;
        border-bottom: 0;
        width: 100%;
        align-items: flex-start !important;

        h5 {
          display: none;
        }

        p {
          margin-bottom: 0 !important;
        }

        .ms-3 {
          padding-bottom: 20px;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 20px;
          width: 100%;
        }
      }

      img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin-top: 10px;
      }
    }
  }

  .d-md-none {
    display: none;
  }

  .smalltopbar .messsagemenu li.active::after {
    bottom: -25px;
  }

  .smallbg-white {
    background-color: #fff !important;
  }

  .ms-sm-2 {
    margin-left: 5px;
  }

  .personalinfo {
    .btn {
      width: 100% !important;
    }

    .verify-btn{
      width: 100px !important;
      padding: 10px !important;
    }
  }

  .logout-navbar {
    display: none !important;
  }

  .mobile-nav {
    display: block !important;
  }

  .main-slider {
    padding: 20px 0;
    margin-bottom: 20px;

    .hero-banner {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background: var(--primary-color) !important;
        }

        .swiper-pagination-bullet {
          background: var(--light-bg);
        }
      }
    }
  }

  .how-it-work {
    padding-bottom: 150px;
    padding-top: 20px;

    .boxes {
      margin: 70px 0 -230px;

      .col-lg-4 {
        &:nth-child(2) {
          border-left: 0;
          border-right: 0;
          border-top: 1px solid var(--border-color);
          border-bottom: 1px solid var(--border-color);
          padding: 20px;
          margin: 20px 0;
        }
      }
    }
  }

  .itmes-list {
    padding-top: 290px;

    .list-boxes {
      margin: 100px 0 80px;
      height: auto;
    }
  }

  .footer {
    display: none !important;
  }

  .mobile-footer {
    display: inline-flex !important;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    min-height: 68px;
    z-index: 99;
    border-top: 1px solid var(--primary-color);
  }

  .modal {
    .header-area {
      .nav.nav-pills {
        margin: 0 15px 25px;

        .nav-item {
          width: 50%;

          a {
            width: 100% !important;
          }
        }
      }
    }
  }

  .card {
    margin-bottom: 20px;
  }

  .top-area {
    padding: 20px 0;

    &.mobile-breadcrumb {
      .search-form {
        a {
          &.search-icon {
            position: absolute;
            top: 5px;
            right: 15px;
          }
        }
      }

      .btn-theme {
        height: 40px;

        &:hover {
          background: var(--primary-color);
        }
      }
    }
  }

  .mobile-grid-btn {
    .nav-item {
      .nav-link {
        position: fixed;
        z-index: 9;
        bottom: 75px;
        text-align: center;
        background: #fff;
        width: 100%;
        border-radius: 40px 40px 0 0;
        padding: 5px 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: #455a64;
      }
    }
  }

  .mobile-map-btn {
    .nav-item {
      .nav-link {
        position: fixed;
        z-index: 9;
        bottom: 120px;
        left: 40%;
        text-align: center;
        background: var(--secondary-color);
        width: auto;
        border-radius: 15px;
        padding: 5px 20px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus-visible {
          box-shadow: none;
        }
      }
    }
  }

  .category-icons {
    background: #fff;
    padding: 15px 0;
    border-top: 15px solid #f3f3f3;

    p {
      color: #455a64 !important;
      text-align: center;
      margin: 5px 0 0;
    }

    span {
      text-align: center;
      margin: 0 auto;
      display: block;
    }

    img {
      border-radius: 0%;
    }

    .active-cat {
      position: relative;

      &::after {
        width: 100%;
        left: 0;
        height: 4px;
        background-color: #16ff96;
        content: "";
        bottom: -15px;
        position: absolute;
        border-radius: 8px 8px 0 0;
      }

      p {
        color: #000;
        font-weight: 800;
      }
    }
  }

  .category-detailview {
    margin-bottom: 90px;

    .image-gallery-slide {
      height: 100%;

      .center {
        position: relative;
        height: 100%;
      }
    }

    .image-gallery-right-nav {
      right: 40% !important;
    }

    .image-gallery-left-nav {
      left: 33% !important;
    }

    .blue-box {
      background: transparent;
      border-radius: 5px;
      border: 2px solid #0074df;
      color: #0074df;
      padding: 15px;
    }
  }

  .itmes-list {
    &.lender-items {
      .list-boxes {
        margin: 40px 0 -150px;
        height: auto;
      }
    }
  }

  .how-it-work {
    &.lender-work {
      padding: 50px 0 80px;
      margin-bottom: 210px;
    }
  }

  .addresscard {
    margin-bottom: 20px;
    min-height: auto;

    &.addadress {
      min-height: 200px;
      margin-top: 0px;
    }
  }

  .detail-view {
    &.checkout {
      .font16 {
        font-size: 12px;
      }
    }
  }

  .checkout {
    .card {
      width: 100% !important;
    }
  }

  .accountmain {
    &.mobile-list {
      .listcard {
        display: flex;
        align-items: center;

        border: 1px solid var(--border-color1);
        box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
        margin-bottom: 20px;
        border-radius: 15px;
        padding: 15px;
        gap: 10px;
        background: #fff;

        .card-img-top {
          width: 120px;
          height: 80px;
          object-fit: cover;
          border-radius: 15px;
        }
      }
    }
  }

  .mobile_chat {
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    .accountmain {
      &.chat-page {
        max-height: calc(100vh - 35vh) !important;
        min-height: auto;
        padding-bottom: 0px !important;
        overflow-y: hidden;
      }
    }

    .mainbox_outer {
      margin-bottom: 0 !important;
      height: 100%;
      min-height: 100%;
    }
  }

  .new-mobile-list {
    .accordion-button {
      &::after {
        opacity: 0;
      }
    }
  }

  .mobilesidebar {
    min-height: calc(100vh - 410px) !important;

    .nav-item {
      margin-bottom: 15px;

      a {
        font-size: 16px;
        font-weight: var(--font-bold);
      }
    }

    .icons {
      width: 30px;
      height: 30px;
      margin-right: 15px;

      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-top: -10px;
      }
    }
  }

  .accordion {
    .accordion-item {
      .accordion-body {
        border: 0 !important;
      }
    }
  }

  .middle-area {
    overflow-y: scroll;
    height: calc(100vh - 200px) !important;
  }

  .modal {
    .middle-area {
      height: auto !important;
    }

    .header-area {
      .nav {
        &.nav-pills {
          a {
            padding: 8px 6px;
          }
        }
      }
    }
  }

  .accountmain,
  .accountmain {
    .tab-content {
      min-height: fit-content !important;
    }
  }

  .sidebarnav {
    .mobilesidebar {
      height: 44vh;
    }
  }

  .image-gallery-slide-wrapper {
    height: auto;
    min-height: 200px;
  }

  .calender {
    .fc {
      .fc-bg-event {
        position: relative;
        background: rgba(22, 255, 150, 0.1647058824) !important;
        opacity: 1;
        height: 100%;
        width: 100%;

        p {
          margin: 0;
          opacity: 0;
        }
      }

      .blocked_class {
        &.fc-bg-event {
          left: 10px;
          top: -5px;
        }
      }
    }

    .fc {
      .fc-daygrid-day-top {
        justify-content: center;
        z-index: 0;
      }
    }

    .fc-event {
      background-color: #16ff96 !important;
    }

    .fc-direction-ltr {
      .fc-daygrid-event {
        &.fc-event-end {
          .fc-event-time {
            opacity: 0;
          }
        }
      }
    }

    a {
      &.fc-event {
        &.fc-event-future {
          &.multiple_day {
            &.fc-daygrid-event {
              &.fc-daygrid-block-event {
                &.fc-h-event {
                  height: 30px;
                  min-height: 30px;
                }
              }
            }
          }
        }
      }
    }

    .fc {
      .fc-daygrid-day-number {
        padding: 10px 0;
        position: relative;
        font-size: 13px;
        line-height: 24px;
      }
    }

    .fc-theme-standard {
      td {
        width: 50px;
        height: 50px;
        vertical-align: middle;
      }

      th {
        width: 50px;
        height: 43px;
        vertical-align: middle;
        text-align: center;
      }
    }

    .fc {
      .fc-daygrid-body-unbalanced {
        .fc-daygrid-day-events {
          min-height: auto;

          position: static;
        }
      }
    }
  }

  .calender {
    .fc {
      .single_day {
        &.fc-bg-event {
          .price {
            display: block;
            margin: 10px 0 0;
            position: static;
            top: 10px;
            right: 0;

            p {
              margin: 20px -0 0px 0;
              opacity: 1;
              font-size: 8px !important;
              text-align: center;
            }
          }
        }
      }

      .fc-daygrid-event {
        margin-top: 0 !important;
        min-height: 45px !important;
        margin-left: 0 !important;
      }
    }

    .fc-direction-ltr {
      .fc-daygrid-event {
        &.fc-event-start {
          margin-top: 0;
          min-height: 40px;
        }
      }
    }
  }

  .fc {
    .fc-daygrid-day-bottom {
      margin: 0px !important;
    }
  }

  .fc-direction-ltr {
    .fc-daygrid-event {
      &.fc-event-end {
        min-height: 30px;
        height: 30px;
        border-radius: 0 100px 100px 0;
      }
    }
  }

  .accountmain {
    .room {
      img {
        width: 160px;
        height: 160px;
      }

      h4 {
        text-align: center;
      }
    }
  }

  .main-slider {
    .hero-banner {
      .slide-content {
        h2 {
          font-weight: 300;
          font-style: italic;
        }
      }
    }
  }

  .how-it-work {
    h2 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  .mainbox_outer {
    h2 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .fixed_header {
    position: fixed;
    width: 100%;
  }
}

.top-breadcrumb {
  &.top-area {
    &.mobile-breadcrumb {
      .search-form {
        position: relative;

        span {
          &.search-icon {
            position: absolute;
            top: 5px;
            right: 5px;
          }

          &.cross {
            position: absolute;
            top: 5px;
            right: 25px;
          }
        }
      }
    }
  }
}

.filter-btn {
  .btn {
    padding: 9px 21px !important;
  }
}

.calender {
  .fc-direction-ltr {
    .fc-daygrid-event {
      &.fc-event-start {
        margin-top: 10px;
        min-height: 50px;
      }

      &.fc-event-end {
        margin-top: 10px;
        min-height: 50px;
      }
    }
  }

  .fc-event-main {
    p {
      align-items: center;

      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 5px;
        object-fit: cover;
      }
    }
  }
}

.fc {
  .fc-daygrid-event {
    margin-top: 0;
    min-height: 50px;
  }
}

@media (max-width: 767px) {
  .calender .fc .fc-daygrid-day-top {
    width: 30px !important;
    height: 30px !important;
    padding: 0 !important;
    top: 5px;
    left: 5px;
  }

  .calender .fc .single_day.fc-bg-event {
    left: 5px !important;
    top: 5px !important;
    transform: none !important;
    height: 30px !important;
    width: 30px !important;
  }

  .calender .fc .single_day.fc-bg-event p img {
    width: 30px !important;
    height: 30px !important;
  }

  .calender .fc .fc-daygrid-day-number {
    line-height: 12px !important;
  }

  td.fc-day.fc-day-tue.fc-day-past.fc-day-other.fc-daygrid-day .fc-daygrid-event-harness.fc-daygrid-event-harness-abs {
    display: none;
  }

  .fc-daygrid-event-harness {
    position: absolute !important;
    top: 0;
  }

  .fc .fc-daygrid-day-frame {
    height: 50px !important;
  }

  .calender .fc-theme-standard td {
    max-height: 50px !important;
  }

  .mobile_module {
    height: 100%;
    left: 0;
    right: 0;
    position: fixed;
    overflow-y: hidden;
    overscroll-behavior: none;

    .mobile-nav {
      position: static;
      width: 100%;
      height: auto;
    }

    .inner_box {
      position: relative;
      margin-bottom: 40px;
      padding: 20px 0 70px;
      height: calc(100vh - 130px);
      overflow-y: auto;
      background-color: #fff;
    }
  }
  .mobile-nav{

    .user-img{
      .initials-circle{
        width: 40px !important;
        height: 40px !important;
        font-size: unset !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .calender {
    .fc-event-main {
      p {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .chatboxmain {
    min-height: 280px;
    max-height: 280px;
  }
}